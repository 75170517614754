import CircularXX from './Fonts/CircularXXWeb-Regular.woff';
import CircularXXBook from './Fonts/CircularXXWeb-Book.woff';
import CircularXXBold from './Fonts/CircularXXWeb-Bold.woff';
import CircularXXMedium from './Fonts/CircularXXWeb-Medium.woff';
import CircularXXLight from './Fonts/CircularXXWeb-Light.woff';
import CircularXXLightItalic from './Fonts/CircularXXWeb-LightItalic.woff';
import { createMuiTheme } from '@material-ui/core/styles'
 
const circularXX = {
 fontFamily: 'CircularXX',
 fontStyle: 'normal',
 fontDisplay: 'swap',
 fontWeight: 600,
 src: `
   local('CircularXX'),
   local('CircularXXWeb-Bold'),
   url(${CircularXX}) format('woff')
 `,
 unicodeRange:
   'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const circularXXBook = {
  fontFamily: 'CircularXXBook',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('CircularXXWeb-Book'),
    url(${CircularXXBook}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
 };

 const circularXXBold = {
  fontFamily: 'CircularXXBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('CircularXXWeb-Bold'),
    url(${CircularXXBold}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
 };

 const circularXXMedium = {
  fontFamily: 'CircularXXMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('CircularXXWeb-Medium'),
    url(${CircularXXMedium}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
 };

 const circularXXLight= {
  fontFamily: 'CircularXXLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('CircularXXWeb-Light'),
    url(${CircularXXLight}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
 };

 const circularXXLightItalic= {
  fontFamily: 'CircularXXLightItalic',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    local('CircularXXWeb-LightItalic'),
    url(${CircularXXLightItalic}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
 };

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#384967',
    },
    secondary: {
      main: '#F1BE48'
    },
  },
    typography: {
        fontFamily: ['CircularXX', 'CircularXXBook'].join(','),
        button: {
          fontSize: '5rem',
        },
    },
    overrides: {
        MuiCssBaseline: {
          '@global': {
            '@font-face': [circularXX, circularXXBook, circularXXBold, circularXXMedium, circularXXLight, circularXXLightItalic],
          },
        },
        // Style sheet name
      MuiTouchRipple: {
        // Name of the rule
        child: {
          // Some CSS
          backgroundColor: "white"
        }
      },
      }
  });

  theme.typography.h3 = {
    fontSize: '35px',
  };

  theme.typography.h4 = {
    fontFamily: 'CircularXXMedium',
    fontSize: '25px',
  };

  theme.props = {
    MuiButton: {
      disableElevation: true,
    }
  }

  export default theme;