import React, { useEffect } from 'react';
import PreviousButton from '../Components/PreviousButton';
import NextButton from '../Components/NextButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormControl, Container } from '@material-ui/core';
import Header from '../Components/Header';

const OsiaScreen = (props) => {

    return (
        <FormControl className="fade-in" fullWidth>
            <Header
                title='Your current sound processor'
                subtitle={<Typography className="headerSubTitle" variant="h6">Osia<sup>®</sup> recipients, please check back in the coming years for upgrade options.</Typography>}
                mainQuestion={<Typography className="mainQuestion" variant="h4">At Cochlear we are continuously innovating. 
                The Cochlear<sup>™</sup> Osia<sup>®</sup> System is some of our newest technology and was released in 2019. 
                If you are an Osia<sup>®</sup> recipient, please check back in the coming years for upgrade options 
                that will become available to you.</Typography>}>
            </Header>
            <Container xs={12}>
                <div className="directionButtonsWrapper">
                    <PreviousButton
                        variant="contained"
                        size="large"
                        aria-label="contained large button"
                        disableNext={false}
                        >Previous</PreviousButton>
                        <NextButton
                        style={{paddingLeft: '25px'}}
                            variant="contained"
                            size="large"
                            location={'/'}
                            aria-label="contained large button"
                            disableNext={false}
                        >Back to start</NextButton>
                    </div>
            </Container>
        </FormControl>

    )
}

export default OsiaScreen;