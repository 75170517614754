import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import NextButton from '../../Components/NextButtonFullWidth';
import IconButton from '../../Components/IconButton';
import IconButtonPreviousButton from '../../Components/IconButtonPreviousButton';
import IconButtonPDFDownload from '../../Components/IconButtonPDFDownload';
import { numberWithCommas } from '../../Utils/Utils';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { FormControl } from '@material-ui/core';
import Typography from '../../Components/Typography';
import Box from '@material-ui/core/Box';
import SelfPayContainer from '../../Components/SelfPayContainer';
import ResultsDisclaimers from '../../Components/ResultsDisclaimers';
import { SelfPayAccordion, AccordionSummary, AccordionDetails } from '../../Components/SelfPayAccordion';
import { CreateResults, BAHA_UPGRADE, MORE } from '../../Utils/CostCalculator';

const Results = (props) => {

    let allResults = useSelector(state => state);
    let resultObject = CreateResults(allResults);

    const history = useHistory();
    const [expanded, setExpanded] = React.useState('');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        //tracking
        props.handleTracking({'Page Name': 'Results -> Less Path'});
        },[],
      );

    return (
        <FormControl className="fade-in" fullWidth>
            <Box className="finalResultsHeaderBox">
                <Grid container justify="flex-end">
                    <Grid item className="resultsLogo">
                        <img src="images/logo.svg" alt="Cochlear Logo" />
                    </Grid>
                </Grid>
            </Box>
            <Box className="resultsHeaderBox">
                <Container className="resultsHeaderIcons">
                    <IconButton 
                        location="/"
                        image="images/startover.svg">Start<br />Over</IconButton>
                    <IconButtonPreviousButton 
                        image="images/Results_back.svg">Previous<br />Page</IconButtonPreviousButton>
                     <IconButtonPDFDownload 
                        finalResults={resultObject}
                        image="images/downloadResults.svg">Download<br />Results</IconButtonPDFDownload>
                    <IconButton 
                        location="/emailinfo"
                        image="images/emailRSM.svg">Email<br />Cochlear expert</IconButton>
                    <Grid container xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <Typography className="resultsTitle" variant="h4">Your potential insurance out-of-pocket costs</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="resultsHeaderExplanation" variant="h6">Your out-of-pocket cost is an estimate based on the information you provided and averages for customers with similar insurance.<sup>||</sup> For more information on your specific circumstances and upgrade options, click the link to email your results to Cochlear and one of our specialist will be in touch. </Typography>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography className="planForTheFutureTitle" variant="h4">Plan for the future!</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="resultsHeaderExplanation" variant="h6">Keep the following average out-of-pocket cost for customers with similar insurance in mind for when you’ll become eligible for an upgraded sound processor in the next few years:<sup>*</sup></Typography>
                        </Grid> */}
                    </Grid>

                </Container>
            </Box>
            <Box className="resultsBox">
                <Container className="resultsBody" xs={12}>
                    <Grid container xs={12}>
                        <Grid item className="resultsImageEstimateBox" xs={12}>
                            <img style={{ width: '270px', height: 'auto' }} src={resultObject.device === BAHA_UPGRADE ? "images/Results_Baha6.png" : "images/Results_Kanso2_or_Nucleus7.png"} alt={resultObject.device} />
                            <Typography className='boldResultWithImage' variant="h3">${numberWithCommas(resultObject.outOfPocket)} for a<br /><span dangerouslySetInnerHTML={{ __html: resultObject.device }} /> Sound Processor</Typography>
                        </Grid>
                    </Grid>

                    <SelfPayAccordion square expanded={expanded === 'selfPayPanel'} onChange={handleChange('selfPayPanel')}>
                        <AccordionSummary aria-controls="selfPay-content">
                            <Typography className="selfPayQuestion" variant="h4">What would my cost be with Self-Pay today?<sup>†</sup></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} style={{ padding: '25px' }}>
                                <Grid item xs={12}>
                                    <Typography className="selfPayExplanation" color="white" variant="h5">Self-pay is a program to offer a discount to patients who might not be upgrade
                                        eligible or prefer not to use insurance.
                                        Self-pay allows patients to pay in cash and upgrade whenever they want!</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelfPayContainer fullWidth>
                                        <Typography className="selfPayOptionType" variant="h5">Self-Pay with<br />NO trade-in</Typography>
                                        <Typography className='boldResult' variant="h3">${numberWithCommas(resultObject.selfPayCost)} for a<br /><span dangerouslySetInnerHTML={{ __html: resultObject.device }} /><br />Sound Processor</Typography>
                                    </SelfPayContainer>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelfPayContainer fullWidth>
                                        <Typography className="selfPayOptionType" variant="h5">Self-Pay with trade-in<br />of current device‡</Typography>
                                        <Typography className='boldResult' variant="h3">${numberWithCommas(resultObject.selfPayTradeIn)} for a<br /><span dangerouslySetInnerHTML={{ __html: resultObject.device }} /><br />Sound Processor</Typography>
                                    </SelfPayContainer>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </SelfPayAccordion><Grid item width={1} xs={12}>
                        <NextButton
                            variant="contained"
                            size="large"
                            location='/emailinfo'
                            aria-label="contained large button"
                            disableNext={false}>
                            Email results to Cochlear<br />to schedule a free upgrade consultation
                        </NextButton>
                    </Grid>
                </Container>
            </Box>
            <box className="greySummaryBox">
                <Container>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <Typography className="resultsSectionTitle" color="white" style={{ margin: '24px 0 23px 0' }} variant="h4">Summary of your answers:</Typography>
                        </Grid>
                        <Grid container className="leftResultsBox" xs={12} md={6} spacing={3}>
                            <Grid item  className="ResultDiv" xs={12}>
                            <Grid item className="resultsItem" xs={12} md={5}>
                                <Typography className='savedPropertyPrompt' variant="h5">Current device:</Typography>
                            </Grid>
                            <Grid className="resultsItem" item xs={12} md={7}>
                                <Typography className='savedProperty' variant="h5"><span dangerouslySetInnerHTML={{ __html: allResults.current_device }} /></Typography>
                            </Grid>
                            </Grid>

                            <Grid item  className="ResultDiv" xs={12}>
                            <Grid className="resultsItem" item xs={12} md={5}>
                                <Typography className='savedPropertyPrompt' variant="h5">Time owned:</Typography>
                            </Grid>
                            <Grid className="resultsItem" item xs={12} md={7}>
                                <Typography className='savedProperty' variant="h5">
                                    {allResults.time_with_device === MORE ? 'MORE than 5 years' : 'LESS than 5 years'}</Typography>
                            </Grid>
                            </Grid>

                            <Grid item  className="ResultDiv" xs={12}>
                            <Grid className="resultsItemLarge" item xs={12} md={5}>
                                <Typography className='savedPropertyPrompt' variant="h5">Insurance:</Typography>
                            </Grid>
                            <Grid className="resultsItemLarge" item xs={12} md={7}>
                                <Typography
                                    className='savedProperty'
                                    variant="h5">{allResults.insurance}</Typography><Typography
                                    className='savedPropertyDisclaimer'
                                    variant="h5">(Additional insurance<br/> providers will be billed<br/> separately)</Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="rightResultsBox" container xs={12} md={6} spacing={3}>
                            <Grid item  className="ResultDiv" xs={12}>
                                <Grid className="resultsItem" item xs={12} md={5}>
                                    <Typography className='savedPropertyPrompt' variant="h5">Group:</Typography>
                                </Grid>
                                <Grid className="resultsItem" item xs={12} md={7}>
                                    {ResultsDisclaimers}
                                    <Typography className='savedProperty' variant="h5">{allResults.insurance_group !== ''? allResults.insurance_group : 'N/A'}</Typography>
                                </Grid>
                            </Grid>

                            <Grid item  className="ResultDiv" xs={12}>
                                <Grid className="resultsItem" item xs={12} md={5}>
                                    <Typography className='savedPropertyPrompt' variant="h5">Deductible:</Typography>
                                </Grid>
                                <Grid className="resultsItem" item xs={12} md={7}>
                                    <Typography className='savedProperty' variant="h5">N/A</Typography>
                                </Grid>
                            </Grid>

                            <Grid item  className="ResultDiv" xs={12}>
                                <Grid className="resultsItemLarge" item xs={12} md={5}>
                                    <Typography className='savedPropertyPrompt' variant="h5">Deductible paid:</Typography>
                                </Grid>
                                <Grid className="resultsItemLarge" item xs={12} md={7}>
                                    <Typography className='savedProperty' variant="h5">N/A</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </box>
            <box>
                <ResultsDisclaimers />
            </box>
        </FormControl>
    )
}

export default Results;