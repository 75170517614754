import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 26px',
    color: "#384967",
    textTransform: 'none',
    textAlign: 'center',
    fontSize: 25,
    backgroundColor: "#F1BE48",
    borderRadius: "39px",
    marginTop: '34px',
    marginBottom: '40px',
    font: 'normal normal bold 25px/30px CircularXXMedium',
    '&:hover': {
      backgroundColor: "#FAEBCB",
    },
  },
  endIcon: {
    position: 'absolute',
    right: '23px'
  }, 
}));

const NextButtonFullWidth = ( {children, ...props } ) => {
  const classes = useStyles();

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        endIcon={<ArrowForwardIosIcon />}
        classes={{
          root: classes.root,
          endIcon: classes.endIcon,
        }}
        disabled={props.disableNext}
        component={Link}
        to={props.location}
      >
        {children}
      </Button>
    </>
  );
}

export default NextButtonFullWidth;