export const NUCLEUS = "Nucleus<sup>®</sup> sound processor";
export const KANSO = "Kanso<sup>®</sup> sound processor";
export const BAHA = "Baha<sup>®</sup> sound processor";
export const OSIA = "Osia<sup>®</sup> sound processor";
export const DONTKNOW = "Don't Know";
//have to use plain text for email
export const NUCLEUS_PLAIN_TEXT = "Nucleus® sound processor";
export const KANSO_PLAIN_TEXT = "Kanso® sound processor";
export const BAHA_PLAIN_TEXT = "Baha® sound processor";
export const MORE = "MORE than 5 years";
export const LESS = "LESS than 5 years";
export const MEDICAID = "Medicaid";
export const MEDICARE = "Medicare";
export const PRIVATE_INSURANCE = "Private Insurance";
export const NUCLEUS_KANSO_UPGRADE = 'Nucleus<sup>®</sup> 8 or Kanso<sup>®</sup> 2**';
export const BAHA_UPGRADE = "Baha<sup>®</sup> 6 Max";
export const NUCLEUS_KANSO_UPGRADE_PLAIN_TEXT = 'Nucleus® 8 or Kanso® 2';
export const BAHA_UPGRADE_PLAIN_TEXT = "Baha® 6 Max";

const getCurrentEmailDevice = (current_device) => {
    let current_email_device = '';
    if (current_device === NUCLEUS) {
        current_email_device = NUCLEUS_PLAIN_TEXT;
    }
    else if (current_device === KANSO) {
        current_email_device = KANSO_PLAIN_TEXT;
    }
    else if (current_device === BAHA) {
        current_email_device = BAHA_PLAIN_TEXT;
    }
    return current_email_device;
}

export const CreateResults = (results) => {
   
let output = {};
output.device = '';
output.outOfPocket = 0;
output.selfPayCost = 0;
output.selfPayTradeIn = '0';
output.current_device = results.current_device;
output.current_device_email = getCurrentEmailDevice(results.current_device);
output.time_with_device = results.time_with_device;
output.insurance = results.insurance;
output.hasSecondaryInsurance = results.hasSecondaryInsurance;
output.insurance_group = results.insurance_group;
output.deductible = results.deductible;
output.deductiblePaid = results.deductiblePaid;
  
    switch(results.current_device) {
        case NUCLEUS:
            output.device = NUCLEUS_KANSO_UPGRADE;
            output.device_email = NUCLEUS_KANSO_UPGRADE_PLAIN_TEXT;

            switch(results.time_with_device) {
                case MORE:
                    switch (results.insurance) {
                        case MEDICAID:
                            output.outOfPocket = 4;
                            output.selfPayCost = 10550;
                            output.selfPayTradeIn = 7550;
                            break;
                        case MEDICARE:
                            switch (results.hasSecondaryInsurance) {
                                case true:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                case false:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                            }
                        break;
                        case PRIVATE_INSURANCE:
                                switch(results.insurance_group) {
                                    case 1:
                                    output.outOfPocket = 1073;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;

                                    case 2:
                                    output.outOfPocket = 1399;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                }
                        break;
                    }
                break;

                case LESS:
                    switch (results.insurance) {
                        case MEDICAID:
                            output.outOfPocket = 4;
                            output.selfPayCost = 10550;
                            output.selfPayTradeIn = 7550;
                            break;
                        case MEDICARE:
                            switch (results.hasSecondaryInsurance) {
                                case true:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                case false:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                            }
                        break;
                        case PRIVATE_INSURANCE:
                                switch(results.insurance_group) {
                                    case 1:
                                    output.outOfPocket = 1073;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;

                                    case 2:
                                    output.outOfPocket = 1399;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                }
                        break;
                    }
                break;

                default: 
                output.outOfPocket = ':(';
                output.selfPayCost = ':(';
                output.selfPayTradeIn = ':(';
            }
        break;

        case KANSO:
            output.device = NUCLEUS_KANSO_UPGRADE;
            output.device_email = NUCLEUS_KANSO_UPGRADE_PLAIN_TEXT;

            switch(results.time_with_device) {
                case MORE:
                    switch (results.insurance) {
                        case MEDICAID:
                            output.outOfPocket = 4;
                            output.selfPayCost = 10550;
                            output.selfPayTradeIn = 7550;
                            break;
                        case MEDICARE:
                            switch (results.hasSecondaryInsurance) {
                                case true:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                case false:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                            }
                        break;
                        case PRIVATE_INSURANCE:
                                switch(results.insurance_group) {
                                    case 1:
                                    output.outOfPocket = 1073;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;

                                    case 2:
                                    output.outOfPocket = 1399;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                }
                        break;
                    }
                break;

                case LESS:
                    switch (results.insurance) {
                        case MEDICAID:
                            output.outOfPocket = 4;
                            output.selfPayCost = 10550;
                            output.selfPayTradeIn = 7550;
                            break;
                        case MEDICARE:
                            switch (results.hasSecondaryInsurance) {
                                case true:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                case false:
                                    output.outOfPocket = 213;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                            }
                        break;
                        case PRIVATE_INSURANCE:
                                switch(results.insurance_group) {
                                    case 1:
                                    output.outOfPocket = 1073;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;

                                    case 2:
                                    output.outOfPocket = 1399;
                                    output.selfPayCost = 10550;
                                    output.selfPayTradeIn = 7550;
                                    break;
                                }
                        break;
                    }
                break;
                }
        break;
        
        case BAHA:
        
            output.device = BAHA_UPGRADE;
            output.device_email = BAHA_UPGRADE_PLAIN_TEXT;
            
            switch(results.time_with_device) {
                case MORE:
                    switch (results.insurance) {
                        case MEDICAID:
                            output.outOfPocket = 2;
                            output.selfPayCost = 4600;
                            output.selfPayTradeIn = 'N/A';
                            break;
                        case MEDICARE:
                            switch (results.hasSecondaryInsurance) {
                                case true:
                                    output.outOfPocket = 89;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;
                                case false:
                                    output.outOfPocket = 89;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;
                            }
                        break;
                        case PRIVATE_INSURANCE:
                                switch(results.insurance_group) {
                                    case 1:
                                    output.outOfPocket = 700;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;

                                    case 2:
                                    output.outOfPocket = 474;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;
                                }
                        break;
                    }
                break;

                case LESS:
                    switch (results.insurance) {
                        case MEDICAID:
                            output.outOfPocket = 2;
                            output.selfPayCost = 4600;
                            output.selfPayTradeIn = 'N/A';
                            break;
                        case MEDICARE:
                            switch (results.hasSecondaryInsurance) {
                                case true:
                                    output.outOfPocket = 89;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;
                                case false:
                                    output.outOfPocket = 89;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;
                            }
                        break;
                        case PRIVATE_INSURANCE:
                                switch(results.insurance_group) {
                                    case 1:
                                    output.outOfPocket = 700;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;

                                    case 2:
                                    output.outOfPocket = 474;
                                    output.selfPayCost = 4600;
                                    output.selfPayTradeIn = 'N/A';
                                    break;
                                }
                        break;
                    }
                break;
            }
        break;
        
        default: 
            break;
    }

    //figure out how much they've paid on their deductible and subtract that from the out of pocket
    if (results.deductible > 0 && results.deductiblePaid > 0) {
        let remainderOnDeductible = results.deductible - results.deductiblePaid;

        if (remainderOnDeductible < output.outOfPocket) {
            output.outOfPocket = remainderOnDeductible;
        }
    }

    if (output.outOfPocket < 0) output.outOfPocket = 0;

    return output;
}