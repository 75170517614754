import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    textTransform: 'none',
    border: '0px solid #F1BE48',
    alignItems: 'flex-start',
    marginBottom: '13px',
    justifyContent: 'flex-end',
    
  },
  
  outlinedRoot: {
    minHeight: '75px',
    border: 0,
    borderColor: '#F1BE48',
    color: props => (props.selected ? '#FFFFFF' : "#384967"),
    fontSize: '25px !important',
    paddingLeft: '40px',
    '&:hover $notchedOutline': {
      borderColor: '#F1BE48',
    },
  },
  notchedOutline: {
    borderColor: '#F1BE48',
    '&:focus': {
      borderColor: 'red'
    },
  },
  'input': {
    '&::placeholder': {
      fontStyle: 'italic',
      paddingBottom: 0,
      paddingBottom: '50px',
      color: '#D6D6D6'
    }
  },

  "@media (max-width: 1024px)": {
    'input': {
      '&::placeholder': {
        paddingBottom: '0',
        // color: 'transparent',
        color: '#D6D6D6',
      }
    },
  },

}));


export default function CostTextInput(props, { children }) {
  const classes = useStyles();

  const InputProps = {
    
    classes: { 
      root: classes.outlinedRoot,
      notchedOutline: classes.notchedOutline,
      input: classes['input'],
    },
    startAdornment: <InputAdornment position="start"><Typography variant="h5" style={{ fontSize: 25, color: '#384967' }}>$</Typography></InputAdornment>,
  };

  return (
    <>
      <TextField
        fullWidth
        type="number"
        variant="outlined"
        placeholder="enter dollar amount"
        classes={classes}
        InputProps={{
          classes: { 
            root: classes.outlinedRoot,
            notchedOutline: classes.notchedOutline,
            input: classes['input'],
          },
          startAdornment: <InputAdornment position="start"><Typography variant="h5" style={{ fontSize: 25, color: '#384967' }}>$</Typography></InputAdornment>,
          inputProps: { 
              max: 15000, min: 10 
          }
      }}
        onChange={(e) => props.change(e)}
        value={props.value}
      >
        {children}
      </TextField>
    </>
  );
}
