import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const SelfPayAccordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#FFFFFF',
        border: '1px solid #F1BE48',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginBottom: -1,
        marginTop: 36,
        minHeight: 83,
        color: '#404042',
        '&$expanded': {
            minHeight: 83,
            backgroundColor: '#FAEBCB',
            color: '#384967',
            borderTop: '1px solid #F1BE48',
            borderRight: '1px solid #F1BE48',
            borderBottom: '0px solid #F1BE48',
            borderLeft: '1px solid #F1BE48',
        },
    },
    content: {
        flexGrow: 0,
        JustifyContent: 'center',
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        backgroundColor: '#FAEBCB',
        color: '#444446',
        padding: '0',
        borderRight: '1px solid #F1BE48',
        borderBottom: '1px solid #F1BE48',
        borderLeft: '1px solid #F1BE48',
    },
}))(MuiAccordionDetails);

export default (SelfPayAccordion);