import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setFirstName, setLastName, setZip, setEmail } from '../rootSlice';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import InfoTextInput from '../Components/InfoTextInput';
import { FormControl } from '@material-ui/core';
import Header from '../Components/Header';
import Typography from '@material-ui/core/Typography';
import HrefNextButton from '../Components/HrefNextButton';
import PreviousButton from '../Components/PreviousButton';
import { numberWithCommas } from '../Utils/Utils';
import { CreateResults, MORE } from '../Utils/CostCalculator';


const EmailInfo = (props) => {

    let allResults = useSelector(state => state);
    let resultObject = CreateResults(allResults);
    const dispatch = useDispatch();
    const history = useHistory();
    const scrollRef = useRef(null);
    let emailFirstName = useSelector(state => state.email_first_name);
    let emailLastName = useSelector(state => state.email_last_name);
    let emailAddress = useSelector(state => state.email_address);
    let zip = useSelector(state => state.zip);

    const handleFirstNameChange = (e) => {
        dispatch(setFirstName(e.target.value));
    }

    const handleLastNameChange = (e) => {
        dispatch(setLastName(e.target.value));
    }

    const handleZipChange = (e) => {
        dispatch(setZip(e.target.value));
    }

    const handleEmailChange = (e) => {
        dispatch(setEmail(e.target.value));
    }

    const validateEmail = () => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(emailAddress).toLowerCase());
    }

    const handleDoneClick = () => {

        if (emailFirstName.length == ' ') {
            setFirstNameError(true);
        } else {
            setFirstNameError(false);
        }

        if (emailLastName.length == ' ') {
            setLastNameError(true);
        } else {
            setLastNameError(false);
        }

        if (zip.length < 5) {
            setZipError(true);
        } else {
            setZipError(false);
        }
        
        if (validateEmail() == false) {
            setEmailError(true);
        } 
        else {
            setEmailError(false);
        }

        //all clear
        if (validateEmail() == false || firstNameError == true || lastNameError == true || zipError == true){
            return;
        } else {
            history.push('/done');
            window.location = "mailto:recipient@cochlear.com?cc=" + emailAddress + "&subject=" + emailFirstName + ' ' + emailLastName +
            " got an estimate for an upgraded sound processor&body=Hi Cochlear! I " +
            "just completed the estimated insurance reimbursement calculator and here" +
            " are my results. I would like to learn more about my upgrade options, estimated" +
            " cost and start a plan for my upgrade. I am ready to schedule a consultation to" +
            " learn more with a Cochlear expert in my region, please send a" +
            " booking link to my email, <" + emailAddress + ">.%0D%0D--------------------%0D%0D" +
            " On average the out-of-pocket cost for an upgraded sound processor for customers" +
            " with similar insurance is:*%0D%0D$" + numberWithCommas(resultObject.outOfPocket) + " for a%0D%0D" + resultObject.device_email + " Sound Processor" +
            "%0D%0D--------------------%0D%0DSummary of your answers:%0D%0DFirst Name: " + emailFirstName + "%0D%0DLast Name: " + emailLastName + 
            "%0D%0DZip Code: " + zip + "%0D%0DEmail Address: " + emailAddress + "%0D%0DCurrent device: " + resultObject.current_device_email + "%0D%0D" +
            "Time owned: " + (allResults.time_with_device === MORE ? 'MORE than 5 years' : 'LESS than 5 years') + "%0D%0DInsurance: " + allResults.insurance + " (Additional insurance" +
            " providers will be billed separately)" + 
            (allResults.insurance_group !== '' ? "%0D%0DGroup: " + allResults.insurance_group : "%0D%0DGroup: N/A") + 
            (allResults.deductible !== null ? "%0D%0DDeductible: $" + numberWithCommas(allResults.deductible) : "%0D%0DDeductible: Unknown") + 
            (allResults.deductiblePaid !== null ? "%0D%0DDeductible paid: $" + numberWithCommas(allResults.deductiblePaid) : "%0D%0DDeductible paid: Unknown") + 
            "%0D%0D(Average costs are based on actual out-of-pocket cost paid by recipients with similar insurance companies. Data as of March 31, 2022.)";
        }
    }

    const [emailError, setEmailError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [zipError, setZipError] = useState(false);

    useEffect(() => {
        //tracking
        props.handleTracking({'Page Name': 'Email Info'});
    },[],
  );

    return (
        <FormControl className="fade-in" fullWidth>
            <Header
                title='Your information'
                subtitle='To connect with a Cochlear expert, please provide your name and email.'
                mainQuestion=''>
            </Header>
            <Container fullWidth={true}>
                <Grid container>
                <Grid item xs={12} md={2}>
                        <Typography className="emailFormLabel" variant="h4">First Name</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <InfoTextInput value={emailFirstName} change={handleFirstNameChange}></InfoTextInput>
                        {firstNameError ? <Typography className="emailError" variant="h4">Please Enter Your First Name</Typography> : ''}
                    </Grid>
                </Grid>

                <Grid container style={{ marginTop: '17px' }}>
                    <Grid item xs={12} md={2}>
                        <Typography className="emailFormLabel" variant="h4">Last Name</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <InfoTextInput value={emailLastName} change={handleLastNameChange}></InfoTextInput>
                        {lastNameError ? <Typography className="emailError" variant="h4">Please Enter Your Last Name</Typography> : ''}
                    </Grid>
                </Grid>

                <Grid container style={{ marginTop: '17px' }}>
                <Grid item xs={12} md={2}>
                        <Typography className="emailFormLabel" variant="h4">Email</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <InfoTextInput type="email" value={emailAddress} change={handleEmailChange} fullWidth></InfoTextInput>
                        {emailError ? <Typography className="emailError" variant="h4">Please Enter A Valid Email</Typography> : ''}
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginTop: '17px' }}>
                    <Grid item xs={12} md={2}>
                        <Typography className="emailFormLabel" variant="h4">Zip Code</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                    <InfoTextInput change={handleZipChange} id="zipCodeInputField" value={zip} type="number" maxLength={5} fullWidth></InfoTextInput>
                        {zipError ? <Typography className="emailError" variant="h4">Please Enter A Valid Zip Code (5 numbers)</Typography> : ''}
                    </Grid>
                </Grid>

                <div className="directionButtonsWrapper" ref={scrollRef} >
                    <PreviousButton
                        variant="contained"
                        size="large"
                        aria-label="contained large button"
                        disableNext={false}
                        >Previous</PreviousButton>
                    <HrefNextButton
                        variant="contained"
                        size="large"
                        onClick={handleDoneClick}
                        disableNext={emailFirstName.length < 1 || emailLastName.length < 1 || zip.length < 5 || emailAddress.length < 1}
                    >Continue to email</HrefNextButton>
                </div>
            </Container>
        </FormControl>
    )
}

export default EmailInfo;