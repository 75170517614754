import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import theme from './theme';
import { Provider } from "react-redux";
import store from './store';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import GA4React from "ga-4-react";
//People Productions test Google Analytics
//const ga4react = new GA4React("G-3YYK6C7G7Z");

//Cochlear Google Analytics
const ga4react = new GA4React("G-XWGCQJ6F63");

let persistor = persistStore(store);

(async () => {
  await ga4react.initialize();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.StrictMode>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
);
})();
