import React, { useEffect, useState }  from 'react';
import './App.css';
import { HashRouter as Router, Route } from 'react-router-dom';
import GetStarted from './Views/GetStarted';
import ChooseDevice from './Views/ChooseDevice';
import LessChooseInsurance from './Views/Less/ChooseInsurance';
import MoreChooseInsurance from './Views/More/ChooseInsurance';
import OsiaScreen from './Views/OsiaScreen';
import DontKnowScreen from './Views/DontKnowScreen';
import ChooseInsuranceGroup from './Views/ChooseInsuranceGroup';
import LessResults from './Views/Less/Results';
import MoreResults from './Views/More/Results';
import EnterDeductible from './Views/EnterDeductible';
import EmailInfo from './Views/EmailInfo';
import DoneScreen from './Views/DoneScreen';
import Inspector from './Inspector/Inspector';
import GA4React, { useGA4React } from "ga-4-react";

function App() {

  const ga = useGA4React();

  let handlePageViewTracking = (trackingObject) => {
    if (ga !== undefined) {
      ga.pageview(trackingObject['Page Name']);
      ga.gtag("event", "Cochlear Page View", trackingObject,
      );
    }else {
      //console.log("Google Analytics is not ready!");
    }
  } 

  return (
    <div className="viewHolder">
      {/* <Inspector /> */}
        {/* <Router basename='/cochlear/estimator-tool'> */}
        <Router>
          <Route exact path="/" render={() => (<GetStarted handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/device-type" render={() => (<ChooseDevice handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/l-choose-insurance" render={() => (<LessChooseInsurance handleTracking={handlePageViewTracking} isAuthed={true} />)} /> 
          <Route path="/m-choose-insurance" render={() => (<MoreChooseInsurance handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/choose-insurance-group" render={() => (<ChooseInsuranceGroup handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/l-results" render={() => (<LessResults handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/m-results" render={() => (<MoreResults handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/deductible" render={() => (<EnterDeductible handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/emailinfo" render={() => (<EmailInfo handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/done" render={() => (<DoneScreen handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/unknown" render={() => (<DontKnowScreen handleTracking={handlePageViewTracking} isAuthed={true} />)} />
          <Route path="/osia" render={() => (<OsiaScreen handleTracking={handlePageViewTracking} isAuthed={true} />)} />
        </Router>
</div>
  );
}

export default App;
