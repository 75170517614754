import { createSlice } from '@reduxjs/toolkit'

const rootSlice = createSlice ({
    name:"root",
    initialState: {
        current_device: "",
        time_with_device: "",
        time_button_selected: '',
        insurance: "",
        hasSecondaryInsurance: "",
        insurance_group: "",
        deductible: null,
        deductiblePaid: null,
        name: "",
        email_first_name: '',
        email_last_name: '',
        zip: '',
        email_address: '',
        amountDontKnowClicked: false,
        amountPaidDontKnowClicked: false
    },
    reducers: {
        chooseDevice: (state, action) => { state.current_device = action.payload },
        chooseTime: (state, action) => { state.time_with_device = action.payload },
        chooseTimeButton: (state, action) => { state.time_button_selected = action.payload },
        chooseInsurance: (state, action) => { state.insurance = action.payload },
        chooseSecondary: (state, action) => { state.hasSecondaryInsurance = action.payload },
        chooseDeductible: (state, action) => { state.deductible = action.payload },
        chooseDeductiblePaid: (state, action) => { state.deductiblePaid = action.payload },
        paidYearToDate: (state, action) => { state.paid_year_to_date = action.payload },
        chooseInsuranceGroup: (state, action) => { state.insurance_group = action.payload },
        setFirstName: (state, action) => { state.email_first_name = action.payload },
        setLastName: (state, action) => { state.email_last_name = action.payload },
        setEmail: (state, action) => { state.email_address = action.payload },
        setZip: (state, action) => { state.zip = action.payload },
        setAmountDontKnowClicked: (state, action) => { state.amountDontKnowClicked = action.payload },
        setAmountPaidDontKnowClicked: (state, action) => { state.amountPaidDontKnowClicked = action.payload },
        resetState: (state) => { 
            state.current_device = ''; 
            state.time_with_device = ''; 
            state.time_button_selected = ''; 
            state.insurance = ''; 
            state.hasSecondaryInsurance = '';
            state.insurance_group = ''; 
            state.deductible = null; 
            state.deductiblePaid = null; 
            state.name = '';
            state.email_first_name = ''; 
            state.email_last_name = ''; 
            state.email_address = ''; 
            state.zip = ''; 
            state.amountDontKnowClicked = false; 
            state.amountPaidDontKnowClicked = false; 
        }
    }
})

export const reducer = rootSlice.reducer;

export const {setFirstName, setLastName, setZip, setEmail, chooseSecondary, chooseDevice, chooseTimeButton, chooseTime, 
    chooseDeductible, chooseDeductiblePaid, chooseInsurance, chooseInsuranceGroup,
    setAmountDontKnowClicked, setAmountPaidDontKnowClicked, resetState } = rootSlice.actions;
