import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';


const OptionButton = withStyles(() => ({
  root: {
      backgroundColor: props => (props.selected ? '#384967' : "#FFFFFF"),
      color: props => (props.selected ? '#FFFFFF' : "#404042"),
      textTransform: 'none',
      fontSize: '25px',
      border: '1px solid #F1BE48',
      minHeight: '75px',
      justifyContent: 'flex-start',
      paddingLeft: '18px',
      marginBottom: '13px',
      textAlign: 'left',
      '&:hover': {
        backgroundColor: props => (props.selected ? "#384967" : "#C6CBD4"),
      },
    },
    }))(Button);

    export default (OptionButton);
