import React, { useEffect } from 'react';
import PreviousButton from '../Components/PreviousButton';
import NextButton from '../Components/NextButton';
import NextButtonHTMLLink from '../Components/NextButtonHTMLLink';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormControl, Container } from '@material-ui/core';
import Header from '../Components/Header';

const DontKnowScreen = (props) => {

    return (
        <FormControl className="fade-in" fullWidth>
            <Header
                title='Your current sound processor'
                subtitle={<Typography className="headerSubTitle" variant="h6">Please log in at <a href="https://mycochlear.com">MyCochlear.com</a> to find your current sound processor.</Typography>}
                mainQuestion={<Typography className="mainQuestion" variant="h">If you don’t know which sound processor you currently
                have please visit <a href="https://mycochlear.com">MyCochlear.com</a> to log in and find out.</Typography>}>
            </Header>
            <Container xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        
                    </Grid>
                </Grid>
                <div className="directionButtonsWrapper" >
                    <PreviousButton
                        variant="contained"
                        size="large"
                        aria-label="contained large button"
                        disableNext={false}
                        >Previous</PreviousButton>
                        <NextButtonHTMLLink
                        style={{paddingLeft: '25px'}}
                            variant="contained"
                            size="large"
                            location='http://www.mycochlear.com'
                            aria-label="contained large button"
                            disableNext={false}
                        >Log in now</NextButtonHTMLLink>
                    </div>
            </Container>
        </FormControl>

    )
}

export default DontKnowScreen;