import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid'

const Header = (props) => {

    return (
        <div>
            <Box className="headerBox">
            {/* <img className="headerImage" /> */}
                <Container>
                    <Grid container justify="flex-end">
                        <Grid item className="logo">
                            <img src="images/logo.svg" alt="Cochlear Logo" />
                        </Grid>
                    </Grid>
                    <Grid className="headerTitleContainer" container spacing={1} justify="flex-end">
                        <Grid item xs={12}>
                            <Typography className="headerTitle" variant="h4">{props.title}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className="headerSubTitle" variant="h6">{props.subtitle}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box>
                <Container>
                    <Grid item xs={12}>
                        <Typography className="mainQuestion" variant="h4">{props.mainQuestion}</Typography>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

export default Header;