import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    textTransform: 'none',
    border: '0px solid #F1BE48',
    alignItems: 'flex-start',
    marginBottom: '13px',
    justifyContent: 'flex-end',
  },
  outlinedRoot: {
    minHeight: '75px',
    border: 0,
    borderColor: '#F1BE48',
    color: props => (props.selected ? '#FFFFFF' : "#384967"),
    fontSize: '25px !important',
    paddingLeft: '10px',
    '&:hover $notchedOutline': {
      borderColor: '#F1BE48',
    },
  },
  notchedOutline: {
    borderColor: '#F1BE48',
    '&:focus': {
      borderColor: 'red'
    },
  },
  input: {
    '&::placeholder': {
      fontStyle: 'italic',
      paddingBottom: 0,
      paddingBottom: '50px',
      color: '#D6D6D6'
    }
  },
}));

const InfoTextInput = ( {children, ...props } ) => {
  const classes = useStyles(props);

  const InputProps = {
    classes: { 
      root: classes.outlinedRoot,
      notchedOutline: classes.notchedOutline,
      input: classes['input'],
    },
  };

  return (
    <>
      <TextField
        fullWidth
        type={props.type}
        variant="outlined"
        placeholder=""
        classes={classes}
        InputProps={InputProps}
        onKeyDown = {(e) => {
          //if it's a numeric field with a maxLength, don't let the e, +, or - keys work
          if (props.maxLength !== undefined) {
            if (e.key === "e" || e.key === "-" || e.key === "+")
            {
                e.preventDefault();
            }
        }
        }}
        onChange={(e) => 
          {
          if (props.maxLength === undefined){
            props.change(e);
          }
          //if a maxLength property is set, only let textfield have that many digits
          else if (e.target.value.length <= props.maxLength) {
            props.change(e)
          }
        }}
        value={props.value}
      >
        {children}
      </TextField>
    </>
  );
}

export default InfoTextInput;
