import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import Typography from './Typography';
import TextLink from './TextLink';
import { Link } from 'react-router-dom';

const ResultsDisclaimers = () => {

    return (
        <div className="disclaimerSection">
        <Container>
            <Grid container className="resultsLinksSectionContainer" spacing={1} justify="flex-end">
                <Grid item xs={12}>
                    <Typography className="resultsSectionTitle" variant="h4">Questions about upgrades? We’ve got answers.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="resultsExplanation" variant="h6">A Cochlear expert is always available to answer any questions you might have. <Link className="mailLink" to="/emailinfo">Contact a Cochlear expert today!</Link> Our website is also full of useful information about our products and how to upgrade. Visit one of the following links for more information.</Typography>
                </Grid>
            </Grid>

            <Grid container className="disclaimerLinks" spacing={1}>
                <Grid className="textLinkGridBox" item xs={12} sm={6}>
                    <a className="textLink" target="_blank" rel="noreferrer" href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/upgrading-your-device">Upgrading your device</a>
                </Grid>
                <Grid className="textLinkGridBox" item xs={12} sm={6}>
                    <a className="textLink" target="_blank" rel="noreferrer" href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/upgrading-your-device/when-to-upgrade">When to upgrade</a>
                </Grid>
                <Grid className="textLinkGridBox" item xs={12} sm={6}>
                    <a className="textLink" target="_blank" rel="noreferrer" href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/upgrading-your-device/nucleus-upgrade-benefits">Nucleus Sound Processor upgrade benefits</a>
                </Grid>
                <Grid className="textLinkGridBox" item xs={12} sm={6}>
                    <a className="textLink" target="_blank" rel="noreferrer" href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/upgrading-your-device/how-to-upgrade">How to upgrade</a>
                </Grid>
                <Grid className="textLinkGridBox" item xs={12} sm={6}>
                    <a className="textLink" target="_blank" rel="noreferrer" href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/upgrading-your-device/baha-upgrade-benefits"><TextLink>Baha Sound Processor upgrade benefits</TextLink></a>
                </Grid>
                <Grid className="textLinkGridBox" item xs={12} sm={6}>
                    <a className="textLink" target="_blank" rel="noreferrer" href="https://www.cochlear.com/us/en/home/ongoing-care-and-support/upgrading-your-device/paying-for-upgrades"><TextLink>Paying for upgrades</TextLink></a>
                </Grid>
            </Grid>
        </Container>

        <Container className="disclaimerContainer">
            <Typography className="disclaimer" paragraph="true" variant="subtitle2" gutterBottom>
            Average costs are based on actual out-of-pocket costs paid by recipients with similar 
            insurance companies. Data as of March 31, 2023. 
            </Typography>
            <Typography className="disclaimer" paragraph="true" variant="subtitle2" gutterBottom>
            <sup>||</sup>To get a more accurate estimate that aligns with your specific insurance plan and benefits, 
            please schedule a free upgrade consultation with a Cochlear expert. 
            </Typography>
            <Typography className="disclaimer" paragraph="true" variant="subtitle2" gutterBottom>
            *Information provided by Cochlear Americas regarding insurance coverage or 
            reimbursement is provided as guidance only and is not intended as reimbursement 
            or legal advice. Cochlear Americas makes no representation or warranty regarding 
            such information or its completeness, accuracy, correctness for a particular 
            purpose, or that following such guidance will result in any form of coverage or 
            reimbursement from any insurer. Information presented is subject to change at any 
            time. To be sure that you have the most current and applicable information available 
            for your unique circumstances, please consult your own experts and seek your own legal 
            advice regarding your reimbursement needs. In all cases, products or services billed 
            must be medically necessary, actually performed and appropriately documented in the 
            medical record. You will be responsible for paying any applicable coinsurance, 
            deductible, or amounts not covered by your insurance to Cochlear. Coverage determinations 
            and out-of-pocket costs may vary for individuals with private insurance.
            </Typography>
            <Typography className="disclaimer" paragraph="true" variant="subtitle2" gutterBottom>
            **The Nucleus 8 and Kanso 2 Sound Processor is not compatible with the Nucleus<sup>®</sup> 22 (N22) 
            Implant due to the Nucleus 8 and Kanso 2 Sound Processor’s size and coil type.
            </Typography>
            <Typography className="disclaimer" paragraph="true" variant="subtitle2" gutterBottom>
            ***The Baha 6 Max Sound Processor is designed to provide the output of up to 55 dB SNHL;
             the Baha family of sound processors includes options that fit up to a 65dB SNHL fitting range. 
             Our specialists or your clinician can help guide you in determining the sound processor 
             that best suits your hearing needs.
            </Typography>
            <Typography className="disclaimer" paragraph="true" variant="subtitle2" gutterBottom>
            †Cochlear is pleased to offer this discounted cash price when self-paying directly 
            with Cochlear. This discount does not apply when placing an upgrade order through 
            Cochlear’s Reimbursement & Insurance Services. Insurance coverage and out-of-pocket 
            expenses will vary depending on your insurance benefit coverage. Please keep in mind, 
            if you choose to self-pay, you may not be able to submit a claim to your insurance on 
            your own if Cochlear is contracted with your private insurance provider. 
            Visit www.Cochlear.com/US/insurancelist to see if Cochlear is contracted with your insurance 
            provider. If Cochlear is contracted with your insurance provider, we suggest 
            placing your order through Cochlear’s Reimbursement and Insurance Services. 
            </Typography>
            <Typography className="disclaimer" paragraph="true" variant="subtitle2" gutterBottom>
            ‡You may trade in one (1) commercially available sound processor when you choose 
            to self-pay. This offer excludes upgrade orders placed through Reimbursement & 
            Insurance Services. Limit 1 trade-in per upgraded device.</Typography>
        </Container>
        </div>
    )
}

export default ResultsDisclaimers;