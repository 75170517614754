import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const CochlearAccordion = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',

        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        backgroundColor: '##FFFFFF',
        border: '1px solid #F1BE48',
        marginBottom: -1,
        marginTop: 20,
        minHeight: '83px',
        
        color: '#404042',
        '&:hover': {
            backgroundColor: '#C6CBD4',
        },
        '&$expanded': {
            minHeight: '83px',
            backgroundColor: '#384967',
            color: '#FFFFFF',
        },
    },
    "@media (max-width: 768px)": {
        root: {
            minHeight: "75px"
        }
      },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export const AccordionSummaryInsurance = withStyles({
    root: {
        backgroundColor: '##FFFFFF',
        border: '1px solid #F1BE48',
        marginBottom: -1,
        marginTop: 20,
        minHeight: 75,
        color: '#404042',
        '&$expanded': {
            minHeight: 56,
            backgroundColor: '#FFFFFF',
            color: '#FFFFFF',
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {
        minHeight: 48,
    },
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        minHeight: '50px',
        padding: '0',
        borderRight: '1px solid #F1BE48',
        borderBottom: '1px solid #F1BE48',
        borderLeft: '1px solid #F1BE48',
        '&:hover': {
            backgroundColor: '#FFFFFF',
        },
    },
}))(MuiAccordionDetails);

export default (CochlearAccordion);