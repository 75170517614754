import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { handlePDFDownload } from '../Utils/Utils'

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ffffff",
    background: 'transparent 0% 0% no-repeat padding-box',
    marginBottom: '40px',
    fontStyle: 'italic',
    textTransform: 'none',
    font: 'normal normal bold 12px/14px CircularXXBook',
    '&:hover': {
       backgroundColor: "transparent",
    },
  }, 
  "@media (max-width: 768px)": {
    root: {
        padding: 0,
    }
  },
  
}));


const IconButtonPDFDownload = ( {children, ...props } ) => {
  const classes = useStyles(props);

  const handlePDFCreation = () => {
    handlePDFDownload(finalResults);
  }

  let finalResults = props.finalResults;
  
  return (
    <>
      <Button
        variant="contained"
        classes={{
          root: classes.root,
        }}
        disabled={props.disableNext}
        disableRipple={true}
        component={Link}
        onClick={handlePDFCreation}
        to={props.location}
      ><div className="resultsButtonContainer">
      <img className="iconButtonImage" src={props.image}/>
        {children}
        </div>
      </Button>
    </>
  );
}

export default IconButtonPDFDownload;