import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const SelfPayContainer = withStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: "#404042",
      textTransform: 'none',
      fontSize: 24,
      border: '1px solid #F1BE48',
      backgroundColor: "#FFFFFF",
      width: '100%',
      height: '100%',
      padding: '25px',
    }
}))(Grid);

export default (SelfPayContainer);