import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { chooseDeductible, chooseDeductiblePaid, setAmountDontKnowClicked, setAmountPaidDontKnowClicked } from '../rootSlice';
import NextButton from '../Components/NextButtonFullWidth';
import Container from '@material-ui/core/Container';
import { FormControl } from '@material-ui/core';
import Header from '../Components/Header';
import OptionButton from '../Components/OptionButton';
import CostTextInput from '../Components/CostTextInput';
import Typography from '@material-ui/core/Typography';
import { MORE } from '../Utils/CostCalculator';

const EnterDeductible = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [disableNext, setDisableNext] = useState(true);
    //Amount has been entered into textfield
    const handleDeductibleAmount = (event) => {
        dispatch(chooseDeductible(event.target.value));
        dispatch(setAmountDontKnowClicked (false));
    };

    const handleDontKnowDeductibleClick = () => {
        dispatch(chooseDeductible(null));
        dispatch(setAmountDontKnowClicked (true));
    };

    const handleDeductiblePaidAmount = (event) => {
        dispatch(chooseDeductiblePaid(event.target.value));
        dispatch(setAmountPaidDontKnowClicked (false));
    };

    const handleDontKnowDeductiblePaidClick = () => {
        dispatch(chooseDeductiblePaid(null));
        dispatch(setAmountPaidDontKnowClicked(true));
    };

    const checkForNextDisable = () => {
        if (selectedDeductible > -1 || amountDontKnowClicked === true) {
            if (selectedDeductiblePaid > -1 || amountPaidDontKnowClicked === true) {
                return false;
            }
        }
        return true;
    }
    let selectedDeductible = useSelector(state => state.deductible);
    let selectedDeductiblePaid = useSelector(state => state.deductiblePaid);
    let amountDontKnowClicked = useSelector(state => state.amountDontKnowClicked);
    let amountPaidDontKnowClicked = useSelector(state => state.amountPaidDontKnowClicked);
    let timeWithDevice = useSelector(state => state.time_with_device);

    useEffect(() => {
            //tracking
            props.handleTracking({'Page Name': 'Enter Deductible'});
            //set next disabled if fields are not filled out
            setDisableNext(checkForNextDisable());
        },[],
    );

    return (
        <FormControl className="fade-in" fullWidth>
            <Header
                title='Your current deductible'
                subtitle='Please answer the following questions to the best of your knowledge.'
                mainQuestion='How much is your deductible?'>
            </Header>
            <Container fullWidth={true}>
                <CostTextInput change={handleDeductibleAmount} value={selectedDeductible === null ? '' : selectedDeductible}></CostTextInput>
                <OptionButton fullWidth selected={amountDontKnowClicked === true? true : false} onClick={() => handleDontKnowDeductibleClick()}>I don't know</OptionButton>
            </Container>
            
            <Container fullWidth={true}>
            <Typography className="mainQuestion" style={{margin: '68px 0 20px 0'}} variant="h4">How much have you paid towards your deductible so far this year?</Typography>
            <CostTextInput change={handleDeductiblePaidAmount} value={selectedDeductiblePaid === null ? '' : selectedDeductiblePaid}></CostTextInput>
                <OptionButton fullWidth selected={amountPaidDontKnowClicked === true? true : false} onClick={() => handleDontKnowDeductiblePaidClick()}>I don't know</OptionButton>
                    <NextButton
                        variant="contained"
                        size="large"
                        location={timeWithDevice === MORE ? '/m-results' : '/l-results'}
                        aria-label="contained large button"
                        disableNext={checkForNextDisable()}
                        >Calculate upgrade cost estimate</NextButton>
            </Container>
        </FormControl>
    )
}

export default EnterDeductible;