import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PreviousButton from '../Components/PreviousButton';
import NextButton from '../Components/NextButton';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FormControl, Container } from '@material-ui/core';
import Header from '../Components/Header';
import { CreateResults, MORE } from '../Utils/CostCalculator';

const DoneScreen = (props) => {

    let emailFirstName = useSelector(state => state.email_first_name);
    let emailLastName = useSelector(state => state.email_last_name);
    let zip = useSelector(state => state.zip);
    let timeWithDevice = useSelector(state => state.time_with_device);
    let emailAddress = useSelector(state => state.email_address);

    let allResults = useSelector(state => state);
    let output = CreateResults(allResults);

    useEffect(() => {
        props.handleTracking({'Page Name': 'Done Screen', 'Zip Code': zip, 'Current Device': output.current_device,
        'Time Owned': output.time_with_device, 'Insurance': output.insurance, 
        'Insurance Group': output.insurance_group, 'Deductible': output.deductible,
        'Deductible Paid': output.deductiblePaid});
        }
    );

    return (

        <FormControl className="fade-in" fullWidth>
            <Header
                title='Done!'
                subtitle='Thank you for completing the cost estimator for a sound processor upgrade.'
                mainQuestion='An email will be generated in the default email client on your desktop or mobile device.'>
            </Header>
            <Container xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography className="hitSend" variant="h6">Remember to hit ‘send’ in your email client to contact a Cochlear expert.</Typography>
                    </Grid>
                </Grid>
                <div className="directionButtonsWrapper">
                    <PreviousButton
                        variant="contained"
                        size="large"
                        aria-label="contained large button"
                        disableNext={false}
                        >Back to results</PreviousButton>
                        <NextButton
                        style={{paddingLeft: '25px'}}
                            variant="contained"
                            size="large"
                            location={'/'}
                            aria-label="contained large button"
                            disableNext={false}
                        >Start over</NextButton>
                </div>
            </Container>
        </FormControl>

    )
}

export default DoneScreen;