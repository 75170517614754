import { jsPDF } from "jspdf";
import $ from "jquery";
import { NUCLEUS_KANSO_UPGRADE, BAHA_UPGRADE } from './CostCalculator';

const PDF_WIDTH = 1063;
const PDF_HEIGHT = 1375;
const DEVICE_IMAGE_WIDTH = 936;
const DEVICE_IMAGE_HEIGHT = 193;
const FONT_SIZE = 26;
let doc;

//create a PDF for download. Used on the Results page.
export const handlePDFDownload = (resultObject) => {

    doc = new jsPDF({
        orientation: "portrait",
            unit: "px",
        format: [PDF_HEIGHT, PDF_WIDTH],
        lineHeight: 1.3
    });

    //choose which image to use
    if(resultObject.device === NUCLEUS_KANSO_UPGRADE) {
        doc.addImage("images/PDF-background-Kanso_Nucleus.jpg", "JPEG", 0, 0, PDF_WIDTH, PDF_HEIGHT);
    }
    else if (resultObject.device === BAHA_UPGRADE) {
        doc.addImage("images/PDF-background-Baha.jpg", "JPEG", 0, 0, PDF_WIDTH, PDF_HEIGHT);
    }

    //set font
    doc.setFontSize(40);
    doc.setFont("helvetica");
    doc.setTextColor('#324261');
    doc.setFont(undefined, "bold");
    

    let fullAmountAndDevice = '$' + numberWithCommas(resultObject.outOfPocket);
    doc.text(fullAmountAndDevice, 485, 350, {maxWidth: 300, align: 'left'});

    doc.setFontSize(35);

     //make label for self pay no trade in
     let outOfPocketNoTradeIn = '$' + numberWithCommas(resultObject.selfPayCost);
     doc.text(outOfPocketNoTradeIn, 300, 800, {maxWidth: 300, align: 'center'});

     //with trade-in
     let outOfPocketWithTradeIn = '$' + numberWithCommas(resultObject.selfPayTradeIn);
     doc.text(outOfPocketWithTradeIn, 750, 800, {maxWidth: 300, align: 'center'});

     //Grey bottom box
     //doc.setFont(undefined, "bold");

     //First column
     doc.setFont(undefined, "normal");
     doc.setFontSize(25);
     doc.text(resultObject.current_device_email, 225, 1050);
     doc.text(resultObject.time_with_device, 225, 1090);
     doc.text(resultObject.insurance, 225, 1125);

     //second column
     doc.text((resultObject.insurance_group === '' ? 'N/A' : resultObject.insurance_group.toString()), 710, 1050);
     doc.text((resultObject.deductible === undefined ? 'N/A' : '$' + numberWithCommas(resultObject.deductible)), 710, 1090);
     doc.text((resultObject.deductiblePaid === undefined ? 'N/A' : '$' + numberWithCommas(resultObject.deductiblePaid)), 710, 1125);

    //make grid
    // doc.setDrawColor('#48ff00');
    // doc.setTextColor('#48ff00');
    // doc.setFontSize(FONT_SIZE);
    // let lineDistance = 50;
    // for (let i = 0; i < PDF_HEIGHT; i ++) {
    //     doc.line(0, i * lineDistance, PDF_WIDTH, i * lineDistance, 'S');
    //     doc.text((i * lineDistance).toString(), 2, i * lineDistance);
    // }
    // for (let j = 0; j < PDF_HEIGHT; j ++) {
    //     doc.line(j * lineDistance, 0, j * lineDistance, PDF_HEIGHT, 'S');
    //     doc.text((j * lineDistance).toString(), j * lineDistance, FONT_SIZE);
    // }

    doc.save("Cochlear-estimate-results.pdf");
}


//Format the Dollar numbers with commas in the thousands place
export const numberWithCommas = (x) => {
    if (x !== null) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
        return '';
    }
}