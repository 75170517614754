import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';


const InsuranceGroupButton = withStyles(() => ({
  root: {
      backgroundColor: props => (props.selected ? '#384967' : "#FFFFFF"),
      color: props => (props.selected ? '#FFFFFF' : "#384967"),
      textTransform: 'none',
      fontSize: '25px',
      border: '1px solid #F1BE48',
      minHeight: '75px',
      padding: '45px',
      height: '100%',
      alignItems: 'flex-start',
      marginBottom: '13px',
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: "#384967",
      },
      '& h5': {
        marginBottom: '30px',
        alignItems: 'flex-start',
      },
      '& h6': {
        textAlign: 'left',
      },
    },
    "@media (max-width: 768px)": {
      root: {
          padding: '20px',
      '& h6': {
        fontSize: '20px',
      },
      }
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      
    },
    
    }))(Button);

    export default (InsuranceGroupButton);
