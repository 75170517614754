import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chooseDevice, chooseTime, chooseTimeButton } from '../rootSlice';
import { CochlearAccordion, AccordionSummary, AccordionDetails } from '../Components/CochlearAccordion';
import NextButton from '../Components/NextButton';
import PreviousButton from '../Components/PreviousButton';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { FormControl } from '@material-ui/core';
import SelectionButton from '../Components/SelectionButton';
import Header from '../Components/Header';
import { NUCLEUS, KANSO, BAHA, OSIA, DONTKNOW, MORE, LESS } from '../Utils/CostCalculator';

const ChooseDevice = (props) => {

    const dispatch = useDispatch();
    const [disableNext, setDisableNext] = useState(true);
    const selectedButtonColor = '#F1BE48';
    const [expanded, setExpanded] = React.useState('');
    const scrollRef = useRef(null)
    // run this function from an event handler or an effect to execute scroll 
    const executeScroll = () => scrollRef.current.scrollIntoView({behavior: "smooth", block: "end"}) ;   
    let current_device = useSelector(state => state.current_device);
    let timeWithDevice = useSelector(state => state.time_with_device);
    let selectedButton = useSelector(state => state.time_button_selected);

    //this is the click of the large accordion buttons that expand to show the dropdowns
    const handleChange = (panel) => (event, newExpanded) => {
        setDisableNext(true);
        setExpanded(newExpanded ? panel : false);
        //If they click a device, erase any previously clicked time buttons
        dispatch(chooseTimeButton(''));
    };


    //The clicking of the 'less than 5 years' or 'more than 5 years'
    const handleRadioClick = (event, device, lengthOfTime) => {
        setDisableNext(false);
        dispatch(chooseDevice(device));
        if (device !== OSIA && device !== DONTKNOW) {
            dispatch(chooseTime(lengthOfTime));
            dispatch(chooseTimeButton(device + lengthOfTime));
        }
        executeScroll();
    };

    const getNextLocation = () => {
        let nextLocation = "";
        if (timeWithDevice === LESS) {
            nextLocation = './l-choose-insurance';
        }
        if (timeWithDevice === MORE) {
            nextLocation = './m-choose-insurance';
        }
        if (current_device === OSIA) {
            nextLocation = './osia'
        }
        if (current_device === DONTKNOW) {
            nextLocation = './unknown'
        }
        return nextLocation;
    }
    

    useEffect(() => {
            //tracking
            props.handleTracking({'Page Name': 'Choose Device'});
            //open the accordion and have the panels selected if the user navigates back
            setExpanded(current_device + 'Panel');
        },[],
    );

    return (
        <FormControl className="fade-in" fullWidth>
            <Header
                title='Your current sound processor'
                subtitle='Please answer the following questions to the best of your knowledge.'
                mainQuestion='Which device do you currently have?'>
            </Header>
            <Container fullWidth={true}>
                <CochlearAccordion square expanded={expanded === NUCLEUS + 'Panel'} onChange={handleChange(NUCLEUS + 'Panel')}>
                    <AccordionSummary aria-controls="nucleusPanel-content" id="nucleusPanel-header">
                        <img className="deviceImage" src="images/Choose_Nucleus_sound_processor.png" />
                        <div className="textColumn">
                            <div className="deviceName">
                                <Typography className="currentDeviceName" variant="h5">Nucleus</Typography>
                                <Typography className="superScript" variant="h5">&reg;</Typography>
                            </div>
                            <Typography className="currentDeviceSoundProcessors" variant="h5">sound processor</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails id="nucleusMore" onClick={(e) => handleRadioClick(e, NUCLEUS, MORE)}>
                        <SelectionButton style={selectedButton === NUCLEUS + MORE ? { backgroundColor: selectedButtonColor } : {}}>
                        <Typography className="haveOwnedFor" variant="h4">I have owned my device for <span className="moreOrLess">MORE</span>  than 5 years</Typography>
                        </SelectionButton>
                    </AccordionDetails>
                    <AccordionDetails id="nucleusLess" onClick={(e) => handleRadioClick(e, NUCLEUS, LESS)}>
                        <SelectionButton style={selectedButton === NUCLEUS + LESS ? { backgroundColor: selectedButtonColor } : {}}>
                        <Typography className="haveOwnedFor" variant="h4">I have owned my device for <span className="moreOrLess">LESS</span>  than 5 years</Typography>
                        </SelectionButton>
                    </AccordionDetails>
                </CochlearAccordion>
                <CochlearAccordion square expanded={expanded === KANSO + 'Panel'} onChange={handleChange(KANSO + 'Panel')}>
                    <AccordionSummary aria-controls="kansoPanel-content" id="kansoPanel-header">
                        <img className="deviceImage" src="images/Choose_Kanso_sound_processor.png" />
                        <div className="textColumn">
                            <div className="deviceName">
                                <Typography className="currentDeviceName" variant="h5">Kanso</Typography>
                                <Typography className="superScript" variant="h5">&reg;</Typography>
                            </div>
                            <Typography className="currentDeviceSoundProcessors" variant="h5">sound processor</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails onClick={(e) => handleRadioClick(e, KANSO, MORE)} id="kansoMore">
                        <SelectionButton style={selectedButton === KANSO + MORE ? { backgroundColor: selectedButtonColor } : {}}>
                        <Typography className="haveOwnedFor" variant="h4">I have owned my device for <span className="moreOrLess">MORE</span> than 5 years</Typography>
                    </SelectionButton>
                    </AccordionDetails>
                    <AccordionDetails id="kansoLess" onClick={(e) => handleRadioClick(e, KANSO, LESS)} >
                        <SelectionButton style={selectedButton === KANSO + LESS ? { backgroundColor: selectedButtonColor } : {}}>
                        <Typography className="haveOwnedFor" variant="h4">I have owned my device for <span className="moreOrLess">LESS</span>  than 5 years</Typography>
                    </SelectionButton>
                    </AccordionDetails>
                </CochlearAccordion>
                <CochlearAccordion square expanded={expanded === BAHA + 'Panel'} onChange={handleChange(BAHA + 'Panel')}>
                    <AccordionSummary aria-controls="bahaPanel-content" id="bahaPanel-header">
                        <img className="deviceImage" src="images/Choose_Baha-5_sound_processor.png" />
                        <div className="textColumn">
                            <div className="deviceName">
                                <Typography className="currentDeviceName"  variant="h5">Baha</Typography>
                                <Typography className="superScript" variant="h5">&reg;</Typography>
                            </div>
                            <Typography className="currentDeviceSoundProcessors" variant="h5">sound processor</Typography>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails id="bahaMore" onClick={(e) => handleRadioClick(e, BAHA, MORE)}>
                        <SelectionButton style={selectedButton === BAHA + MORE ? { backgroundColor: selectedButtonColor } : {}}>
                        <Typography className="haveOwnedFor" variant="h4">I have owned my device for <span className="moreOrLess">MORE</span>  than 5 years</Typography>
                    </SelectionButton>
                    </AccordionDetails>
                    <AccordionDetails id="bahaLess" onClick={(e) => handleRadioClick(e, BAHA, LESS)}>
                        <SelectionButton style={selectedButton === BAHA + LESS ? { backgroundColor: selectedButtonColor } : {}}>
                        <Typography className="haveOwnedFor" variant="h4">I have owned my device for <span className="moreOrLess">LESS</span>  than 5 years</Typography>
                    </SelectionButton>
                    </AccordionDetails>
                </CochlearAccordion>
                {/* <OsiaModalComponent />
                <DontKnowModalComponent /> */}
                <CochlearAccordion square expanded={expanded === OSIA + 'Panel'} onChange={handleChange(OSIA + 'Panel')}>
                <AccordionSummary onClick={(e) => handleRadioClick(e, OSIA)}>
                    <img className="deviceImage" src="images/Choose_Osia_sound_processor.png" />
                    <div className="textColumn">
                        <div className="deviceName">
                            <Typography className="currentDeviceName" variant="h5">Osia</Typography>
                            <Typography className="superScript" variant="h5">&reg;</Typography>
                        </div>
                        <Typography className="currentDeviceSoundProcessors" variant="h5">sound processor</Typography>
                    </div>
                </AccordionSummary>
                </CochlearAccordion>
                <CochlearAccordion square expanded={expanded === DONTKNOW + 'Panel'} onChange={handleChange(DONTKNOW + 'Panel')}>
                <AccordionSummary onClick={(e) => handleRadioClick(e, DONTKNOW)} id="dontknowPanel-header">
                    <img className="deviceImage" src="images/Choose_I_dont_know.svg" />
                    <div className="textColumn">
                        <div>
                            <Typography className="currentDeviceName" variant="h5">I don't know which sound processor I have.</Typography>
                        </div>
                        {/* <div> */}
                            <Typography className="currentDeviceSoundProcessors" variant="h5">That's okay. We will help you figure that out.</Typography>
                        {/* </div> */}
                    </div>
                </AccordionSummary>
                </CochlearAccordion>
            
                <div className="directionButtonsWrapper" ref={scrollRef} >
                    <PreviousButton
                        variant="contained"
                        size="large"
                        aria-label="contained large button"
                        disableNext={false}
                        >Previous</PreviousButton>
                    <NextButton
                        variant="contained"
                        size="large"
                        location={getNextLocation}
                        aria-label="contained large button"
                        disableNext={disableNext}
                        >Next</NextButton>
                </div>
            </Container>
        </FormControl>
    )
}

export default ChooseDevice;