import {
  makeStyles,
  Typography as MuiTypography,
} from '@material-ui/core'
import classNames from 'classnames';

const FONT_WEIGHT = {
  light: 300,
  regular: 600,
  bold: 900,
}

const FONT_SIZE = {
  15: 15,
  25: 25,
  35: 35,
  45: 45
}

const FONT_FACE = {
  book: 'CircularXXBook',
  bold: 'CircularXXBold',
  medium: 'CircularXXMedium',
}

const useStyles = makeStyles({
  overrides: {
    fontWeight: ({ fontWeight }) => FONT_WEIGHT[fontWeight],
    fontSize: ({ fontSize }) => FONT_SIZE[fontSize],
    fontFace: ({ fontFace }) => FONT_FACE[fontFace],
  },
})
export const Typography = ({
  fontWeight,
  fontSize,
  fontFace,
  children,
  ...otherProps
}) => {
  const classes = useStyles({ fontWeight, fontSize, fontFace })
  return (
    <MuiTypography
      {...otherProps}
      className={classNames(
        otherProps.className,
        classes.overrides,
      )}>
      {children}
    </MuiTypography>
  )
}

export default (Typography);