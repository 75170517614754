import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, useHistory } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 26px',
    color: "#FFFFFF",
    textTransform: 'none',
    borderRadius: "39px",
    marginTop: '34px',
    background: '#384967 0% 0% no-repeat padding-box',
    font: 'normal normal bold 25px/30px CircularXXMedium',
    marginRight: '30px',
    '&:hover': {
      backgroundColor: "#495D80",
    },
  },
  "@media (max-width: 768px)": {
    root: {
      marginRight: '0',
    }
  },
  label: {
    color: "#FFFFFF",
  },
}));

const PreviousButton = ( {children, ...props } ) => {

  const history = useHistory();

  const goToLastInHistory = () => {
    //history.goBack();
    console.log("PREVIOUS BUTTON HISTORY ", history);
    if (history.location) {
      history.goBack();
    }
  }

  const classes = useStyles(props);
  return (
    <>
      <Button
        variant="contained"
        startIcon={<ArrowBackIosIcon />}
        classes={{
          root: classes.root,
          startIcon: classes.endIcon,
        }}
        disabled={props.disableNext}
        component={Link}
        to={history.location}
        onClick={goToLastInHistory}
      >
        {children}
      </Button>
    </>
  );
}

export default PreviousButton;