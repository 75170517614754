import React, { useEffect, useState } from 'react'
import NextButton from '../Components/NextButton'
import Box from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { FormControl, Container } from '@material-ui/core'
import { resetState } from '../rootSlice'
import { useDispatch } from 'react-redux'

const GetStarted = (props) => {
    const dispatch = useDispatch()
    //set the state back to nothing
    dispatch(resetState())

    useEffect(() => {
        props.handleTracking({ 'Page Name': 'Get Started' })
    }, [])

    return (
        <FormControl className="homeBackground" fullWidth>
            <Box className="letsGetStartedContentBox" fullWidth={true}>
                <Grid
                    container
                    className="getStartedLogoContainer"
                    justify="flex-end"
                >
                    <Grid item className="getStartedLogo">
                        <img src="images/logo.svg" alt="Cochlear Logo" />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={0} sm={0} md={6} lg={6}></Grid>
                    <Grid item xs={10} sm={6} md={4} lg={4}>
                        <Typography className="getStartedTitle" variant="h3">
                            Insurance cost estimator for a Cochlear™ sound
                            processor upgrade
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={0} sm={0} md={6} lg={6}></Grid>
                    <Grid item xs={12} sm={9} md={5} lg={4}>
                        <Typography className="getStartedSubTitle" variant="h5">
                            <p>
                                Are you interested in upgrading to new sound processor technology now or planning
                                for your next upgrade? We are here to help. Just
                                answer a few simple questions to understand your
                                potential out-of-pocket costs.
                            </p>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            <Box className="letsGetStartedButtonBox">
                <Grid container>
                    <Grid item xs={0} sm={0} md={6} lg={6}></Grid>
                    <Grid
                        container
                        item
                        className="letsGetStartedButton"
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <NextButton
                            style={{ marginTop: '42px' }}
                            variant="contained"
                            size="large"
                            location={'/device-type'}
                            aria-label="contained large button"
                            disableNext={false}
                        >
                            Let's get started
                        </NextButton>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={0} sm={5} md={6} lg={6} xl={0}></Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Typography className="getStartedTime" variant="h5">
                            <p>
                                This questionnaire takes the average<br/>user 2-5
                                minutes to complete.
                            </p>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </FormControl>
    )
}

export default GetStarted
