import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#384967",
    textTransform: 'none',
    fontSize: 20,
    paddingLeft: 0,
    background: 'none',
    textDecoration: 'underline',
    '&:hover': {
      background: 'none',
      textDecoration: 'none',
    },
  }
}));

export default function TextLink( {children} ) {
  const classes = useStyles();

  return (
    <>
      <Button
        variant="contained"
        className={classes.root}
      >
        {children}
      </Button>
    </>
  );
}