import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { chooseInsuranceGroup } from '../rootSlice';
import PreviousButton from '../Components/PreviousButton';
import NextButton from '../Components/NextButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { FormControl, Typography } from '@material-ui/core';
import Header from '../Components/Header';
import InsuranceGroupButton from '../Components/InsuranceGroupButton';
import { MORE } from '../Utils/CostCalculator';

const ChooseInsuranceGroup = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const [disableNext, setDisableNext] = useState(true);
    const handleInsuranceClick = (insuranceGroup) => {
        setDisableNext(false);
        dispatch(chooseInsuranceGroup(insuranceGroup));
    };
    let selectedInsuranceGroup = useSelector(state => state.insurance_group);
    let timeWithDevice = useSelector(state => state.time_with_device);
    
    useEffect(() => {
        //tracking
        props.handleTracking({'Page Name': 'Choose Insurance Group'});
        if (selectedInsuranceGroup !== '') {
                setDisableNext(false);
            }
        }, [],
        );

    return (
        <FormControl className="fade-in" fullWidth>
            <Header
                title={timeWithDevice === MORE ? 'Your current plan' : 'Your future plan' }
                subtitle='Please answer the following questions to the best of your knowledge.'
                mainQuestion='Does your private insurance appear in ‘Group 1’ or ‘Group 2’?'>
            </Header>
            <Container xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                    <InsuranceGroupButton fullWidth selected={selectedInsuranceGroup===1} onClick={() => handleInsuranceClick(1)}>
                        <Typography variant="h5">Group 1</Typography>
                        <Typography className="insuranceGroup" variant="h6">Aetna<br/>Anthem<br/>Cigna<br/>Kaiser<br/>Tricare<br/>United Healthcare</Typography>
                    </InsuranceGroupButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <InsuranceGroupButton fullWidth selected={selectedInsuranceGroup===2} onClick={() => handleInsuranceClick(2)}>
                        <Typography variant="h5">Group 2</Typography>
                        <Typography className="insuranceGroup" variant="h6">Other Private Insurance</Typography>
                    </InsuranceGroupButton>
                    </Grid>
                </Grid>
                <div className="directionButtonsWrapper" >
                    <PreviousButton
                        variant="contained"
                        size="large"
                        aria-label="contained large button"
                        disableNext={false}
                        >Previous</PreviousButton>
                    <NextButton
                        variant="contained"
                        size="large"
                        location={timeWithDevice === MORE ? '/deductible' : '/l-results'}
                        aria-label="contained large button"
                        disableNext={disableNext}
                        >{timeWithDevice === MORE ? 'Next' : 'Calculate upgrade cost estimate' }</NextButton>
                </div>
            
            </Container>
        </FormControl>
    )
}

export default ChooseInsuranceGroup;