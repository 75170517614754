import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const SelectionButton = withStyles(() => ({
    root: {
      color: "#404042",
      display: 'block',
      textTransform: 'none',
      font: 'normal normal bold 24px/30px CircularXXBook',
      fontSize: 20,
      backgroundColor: "#FFFFFF",
      textAlign: 'left', 
      paddingLeft: '18px',
      '&:hover': {
        backgroundColor: "#FAEBCB",
      },
      '&:active': {
        backgroundColor: "#FAEBCB",
      },
      '&:visited': {
        backgroundColor: "#FAEBCB",
      },
      '&:focus': {
        boxShadow: '#FFFFFF',
      },
      width: '100%'
    },
    "@media (max-width: 768px)": {
      root: {
        fontSize: 16,
      }
    },
}))(Button);

export default (SelectionButton);