import React from 'react';
import './Inspector.css';

const PropertyInfo = (props) => (

    <div className="propertyInfoWrapper inspector">
        
            {props.propertyName === 'Class Name:' ?
                <div className="propertyInfoItemWrapperText propertyInfoItemClassNameDiv inspector">
                    <div className="propertyInfoItemName propertyInfoItem inspector">{props.propertyName}</div>
                    <div className="propertyInfoItem inspector">
                        <span className="className inspector">{props.propertyValue}</span>
                    </div>
                </div>
                :
                <div className="propertyInfoItemWrapperText inspector">
                    <div className="propertyInfoItemName propertyInfoItem inspector">{props.propertyName}</div>
                    <div className="propertyInfoItem inspector">
                        <input className="propertyInfoInput inspector" type="text" value={props.propertyValue}
                            onChange={(event) => props.valueChange(event, props.propertyClass, props.propertyName, props.id)} />
                    </div>
                </div>
            }
        
        {props.increment ?
            <div className="propertyInfoItemWrapperButtons inspector">
                <div className="propertyInfoItem inspector">
                    <button className="propertyInfoIncrementButton inspector"
                        onClick={(event) => props.propertyIncrement('down', props.propertyClass, props.propertyName, props.id, props.propertyValue)}>-</button>
                </div>
                <div className="propertyInfoItem inspector">
                    <button className="propertyInfoIncrementButton inspector"
                        onClick={(event) => props.propertyIncrement('up', props.propertyClass, props.propertyName, props.id, props.propertyValue)}>+</button>
                </div>
            </div>
            :
            ''}
    </div>
);

export default PropertyInfo;