import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#ffffff",
    background: 'transparent 0% 0% no-repeat padding-box',
    marginBottom: '40px',
    fontStyle: 'italic',
    textTransform: 'none',
    font: 'normal normal bold 12px/14px CircularXXBook',
    '&:hover': {
       backgroundColor: "transparent",
    },
  }, 
  "@media (max-width: 768px)": {
    root: {
        padding: 0,
    }
  },
  
}));

const IconButtonPreviousButton = ( {children, ...props } ) => {
  const classes = useStyles(props);
  const history = useHistory();

  const goBackInHistory = () => {
    history.goBack();
  }

  return (
    <>
      <Button
        variant="contained"
        classes={{
          root: classes.root,
        }}
        disableRipple={true}
        onClick={goBackInHistory}
      ><div className="resultsButtonContainer">
      <img className="iconButtonImage" src={props.image}/>
        {children}
        </div>
      </Button>
    </>
  );
}

export default IconButtonPreviousButton;