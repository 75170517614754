import React, { useEffect, useState, useRef }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom'
import { chooseInsurance, chooseSecondary } from '../../rootSlice';
import NextButton from '../../Components/NextButton';
import PreviousButton from '../../Components/PreviousButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid'
import { FormControl } from '@material-ui/core';
import Header from '../../Components/Header'
import SelectionButton from '../../Components/SelectionButton';
import { MEDICARE, MEDICAID, PRIVATE_INSURANCE } from '../../Utils/CostCalculator'
import Typography from '@material-ui/core/Typography';
import { CochlearAccordion, AccordionSummary, AccordionDetails } from '../../Components/CochlearAccordion'

const ChooseInsurance = (props) => {
const selectedButtonColor = '#F1BE48';
    const dispatch = useDispatch();
    const history = useHistory();
    const [disableNext, setDisableNext] = useState(true);
    const [expanded, setExpanded] = React.useState('Panel');
    const handleInsuranceClick = (insurance) => {
        setDisableNext(false);
        dispatch(chooseInsurance(insurance));
    };
    const scrollRef = useRef(null)
    // run this function from an event handler or an effect to execute scroll 
    const executeScroll = () => scrollRef.current.scrollIntoView({behavior: "smooth", block: "end"}) ;
    const handleRadioClick = (event, secondaryChoice) => {
        setDisableNext(false);
        dispatch(chooseSecondary(secondaryChoice));
    };
    const handleChange = (panel) => (event, newExpanded) => {
        setDisableNext(true);
        if (panel === MEDICAID || panel === PRIVATE_INSURANCE) {
            setDisableNext(false);
        }
        setExpanded(newExpanded ? panel : false);
        dispatch(chooseInsurance(panel));
    };
    let selectedInsurance = useSelector(state => state.insurance);
    let hasSecondaryInsurance = useSelector(state => state.hasSecondaryInsurance);
    let selectedButton = useSelector(state => state.time_button_selected);

    useEffect(() => {
        //tracking
        props.handleTracking({'Page Name': 'Choose Insurance -> Less Path'});
        //open the accordion and have the panels selected if the user navigates back
        if (selectedInsurance !== '') {
            setExpanded(selectedInsurance);
            setDisableNext(false);
        }
    },[],
  );

    return (
        <FormControl className="fade-in" fullWidth>
            <Header
                title='Your future insurance plan'
                subtitle='Please answer the following questions to the best of your knowledge.'
                mainQuestion='Since you have owned your device for less than 5 years, 
                we want to help you plan for your upgrade in the next few years. 
                What type of insurance do you plan to have in the next 5 years?'>
            </Header>
            <Container fullWidth={true}>
                <CochlearAccordion square expanded={expanded === MEDICARE} onChange={handleChange(MEDICARE)}>
                    <AccordionSummary aria-controls="Medicare-content">
                        <Typography className="optionButtonText" variant="h5">Medicare</Typography>
                    </AccordionSummary>
                    <AccordionDetails onClick={(e) => handleRadioClick(e, true)}>
                        <SelectionButton style={hasSecondaryInsurance === true ? { backgroundColor: selectedButtonColor } : {}}>
                            I have secondary insurance
                        </SelectionButton>
                    </AccordionDetails>
                    <AccordionDetails onClick={(e) => handleRadioClick(e, false)}>
                        <SelectionButton style={hasSecondaryInsurance === false ? { backgroundColor: selectedButtonColor } : {}}>
                            I DO NOT have secondary insurance
                        </SelectionButton>
                    </AccordionDetails>
                </CochlearAccordion>
                <CochlearAccordion square expanded={expanded === MEDICAID} onChange={handleChange(MEDICAID)}>
                    <AccordionSummary aria-controls="Medicaid-content" id="kansoPanel-header">
                    <Typography className="optionButtonText" variant="h5">Medicaid</Typography>
                    </AccordionSummary>
                   
                </CochlearAccordion>
                <CochlearAccordion square expanded={expanded === PRIVATE_INSURANCE } onChange={handleChange(PRIVATE_INSURANCE)}>
                    <AccordionSummary aria-controls="InsuranceProvided-content">
                    <Typography className="optionButtonText" variant="h5">Insurance provided through employer or self-funded plan</Typography>
                    </AccordionSummary> 
                </CochlearAccordion>
                
                <div className="directionButtonsWrapper" ref={scrollRef} >
                    <PreviousButton
                        variant="contained"
                        size="large"
                        aria-label="contained large button"
                        disableNext={false}
                        >Previous</PreviousButton>
                    <NextButton
                        variant="contained"
                        size="large"
                        location={selectedInsurance === MEDICAID || selectedInsurance === MEDICARE ? '/l-results' : '/choose-insurance-group'}
                        aria-label="contained large button"
                        disableNext={disableNext}
                        >{selectedInsurance === MEDICAID || selectedInsurance === MEDICARE ? 'Calculate upgrade cost estimate' : 'Next'}</NextButton>
                </div>
            </Container>
        </FormControl>
    )
}

export default ChooseInsurance;