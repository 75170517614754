import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { NavLink } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 26px',
    color: "#384967",
    textTransform: 'none',
    borderRadius: "39px",
    marginTop: '34px',
    background: '#F1BE48 0% 0% no-repeat padding-box',
    font: 'normal normal bold 25px/30px CircularXXMedium',
    '&:hover': {
      backgroundColor: "#FAEBCB",
    },
    '&:visited': {
      color: "#384967 !important",
    } 
  },
  label: {
    //color: "#384967",
    color: "#FF0000",
  },
}));

const NextButtonHTMLLink = ( {children, ...props } ) => {

  const classes = useStyles(props);
  // console.log("props.disableNext is " + JSON.stringify(props));
  return (
    <>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIosIcon />}
        className={classes.button}
        classes={{
          root: classes.root,
          endIcon: classes.endIcon,
        }}
        disabled={props.disableNext}
        href= "https://mycochlear.com"
      >
        {children}
      </Button>
    </>
  );
}

export default NextButtonHTMLLink;