import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '10px 26px',
    color: "#384967",
    textTransform: 'none',
    borderRadius: "39px",
    marginTop: '34px',
    background: '#F1BE48 0% 0% no-repeat padding-box',
    font: 'normal normal bold 25px/30px CircularXXMedium',
    '&:hover': {
      backgroundColor: "#FAEBCB",
    },
  }
}));

const HrefNextButton = ( {children, ...props } ) => {
  const classes = useStyles(props);
  return (
    <>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIosIcon />}
        href={props.href}
        onClick={props.onClick}
        target="_blank"
        disabled={props.disableNext}
        classes={{
          root: classes.root,
          endIcon: classes.endIcon,
        }}
      >
        {children}
      </Button>
    </>
  );
}

export default HrefNextButton;