import React, { Component } from 'react';
import $ from 'jquery';
import './Inspector.css';
import PropertyInfo from './PropertyInfo';

/*****************************************************************/
// To Use:
// - Make sure you have JQuery installed in your project, if not:
//     In a console run: npm install jquery --save

// At the top level of your app, import this class:
//     (like, in App.js) import Inspector from './Inspector/Inspector'

// Add the tag:
//     <Inspector />
/*****************************************************************/

class Inspector extends React.Component {

    state = {
        classProperties: [],
        log: [],
        currentHTML: '',
        clickable: false,
        minimized: false,
        console: false
    }

    listenersAdded = 0;
    childArray = [];
    clearLog = false;
    currentClass = '';
    incrementingAttr = ['font-size', 'width', 'height', 'padding', 'margin', 'top', 'right', 'bottom',
        'left', 'margin-top', 'margin-right', 'margin-bottom', 'margin-left',
        'padding-top', 'padding-right', 'padding-bottom', 'padding-left', 'border-top-width',
        'border-right-width', 'border-bottom-width', 'border-left-width',
        'letter-spacing', 'word-spacing', 'line-height', 'z-index'];

    nonIncrementingAttr = ['position', 'display', 'flex-direction',
        'justify-content', 'align-items', 'align-self', 'font-family',
        'font-weight', 'font-style', 'color',
        'text-transform', 'text-decoration', 'text-align', 'vertical-align',
        'direction', 'background-color',
        'background-image', 'background-repeat', 'background-position',
        'background-attachment', 'opacity', 'visibility'];

    findChildren = (valuesArray) => {
        for (let value of valuesArray) {

            this.childArray.push(value);
            if (value.children !== null && value.children !== undefined) {
                let newValuesArray = Object.values(value.children);

                this.findChildren(newValuesArray);
            }
        }
    }

    takeOverConsole = () => {
        var original = window.console;
        let oldLog = [...this.state.log];
        let thisClass = this;
        window.console = {
            log: function () {
                if (thisClass.clearLog === true) {
                    oldLog = [...thisClass.state.log];
                    thisClass.clearLog = false;
                }
                original.log.apply(original, arguments)
                oldLog.push(arguments[0]);
                thisClass.setState({ log: oldLog })
                //alert(JSON.stringify(original));
            }
            , warn: function () {
                original.warn.apply(original, arguments)
                // oldLog.push(arguments[0]); 
                // thisClass.setState({log: oldLog})
            }
            , error: function () {
                original.error.apply(original, arguments)
                // oldLog.push(arguments[0]); 
                // thisClass.setState({log: oldLog})
            },
            clear: function () {
                original.clear();
                thisClass.setState({ log: [] });
            }
        }
    }

    setListeners = (valuesArray) => {
        let thisInspector = this;

        for (let value of valuesArray) {
            if (value.getAttribute('class') !== null && value.getAttribute('class') !== 'undefined') {
                let currentValue = value;
                if ($(value).hasClass("inspector") || $(value).hasClass("container")) {
                    //do nothing
                }
                else {
                    this.listenersAdded += 1;
                    currentValue.addEventListener('click', (event) => thisInspector.clickFunction(event))
                }
            }
        }
    }

    removeOldListeners = (valuesArray) => {
        let thisInspector = this;

        for (let value of valuesArray) {
            if (value !== null && value !== 'undefined') {
                let currentValue = value;
                if ($(value).hasClass("inspector") || $(value).hasClass("container")) {
                    //do nothing
                }
                else {
                    currentValue.removeEventListener('click', (event) => thisInspector.clickFunction(event))
                }
            }
        }
    }

    clickFunction = (event) => {
        if (this.state.clickable === false) {
            event.preventDefault();
        }
        let elementClasses = [];
        elementClasses = $(event.target).attr('class');
        this.addPropertiesToList(elementClasses);
    }

    //Get all of the properties of the class and add them to the list for the inspector
    addPropertiesToList = (elementClasses) => {
        if (elementClasses != undefined) {
            let classProperties = [];
            let classesArray = elementClasses.split(' ');
            this.currentClass = classesArray[0];
            for (let i = 0; i < classesArray.length; i++) {
                let thisclassname = classesArray[i];
                if (thisclassname !== '' && thisclassname !== null) {
                    classProperties.push({ propertyClass: thisclassname, propertyName: 'Class Name:', propertyValue: thisclassname, increment: false });
                    for (let j = 0; j < this.incrementingAttr.length; j++) {
                        let currentProperty = $('.' + thisclassname).css(this.incrementingAttr[j]);
                        classProperties.push({ propertyClass: thisclassname, propertyName: this.incrementingAttr[j], propertyValue: currentProperty, increment: true });
                    };
                    //now put in attributes that can not increment
                    for (let k = 0; k < this.nonIncrementingAttr.length; k++) {
                        let currentProperty = $('.' + thisclassname).css(this.nonIncrementingAttr[k]);
                        classProperties.push({ propertyClass: thisclassname, propertyName: this.nonIncrementingAttr[k], propertyValue: currentProperty, increment: false });
                    };
                }
            };

            //update the state and send it to the info pane
            this.setState({ classProperties: classProperties })
        }
    }

    dragElement(dragElement) {

        let elmnt = document.getElementById(dragElement);
        let touchElement = document.getElementById('inspectorHeader');
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        touchElement.onmousedown = dragMouseDown;
        touchElement.ontouchstart = dragTouchDown;

        function dragMouseDown(e) {
            e = e || window.event;
            // get the mouse cursor position at startup
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves
            document.onmousemove = elementDrag;
        }

        function dragTouchDown(e) {
            e = e || window.event;
            // get the mouse cursor position at startup
            pos3 = e.touches[0].clientX;
            pos4 = e.touches[0].clientY;
            //document.onmouseup = closeDragElement;
            document.ontouchend = closeTouchDragElement;
            // call a function whenever the cursor moves
            document.ontouchmove = elementTouchDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function elementTouchDrag(e) {
            e = e;
            e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.touches[0].clientX;
            pos2 = pos4 - e.touches[0].clientY;
            pos3 = e.touches[0].clientX;
            pos4 = e.touches[0].clientY;
            // set the element's new position:
            elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
            elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }

        function closeDragElement() {
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }

        function closeTouchDragElement() {
            // stop moving when touch is released:
            document.ontouchend = null;
            document.ontouchmove = null;
        }
    }

    componentDidMount = () => {
        this.takeOverConsole();

        let children = document.getElementById('root').children;
        let initialArray = Object.values(children);
        this.findChildren(initialArray);
        this.setListeners(this.childArray);
        // Make the DIV element draggable:
        this.dragElement("inspector");
        //write out the page source
        this.state.currentHTML = $("#root").html();

        //print out the screen resolution to the log
        console.log("Screen Resolution: " + window.screen.width * window.devicePixelRatio.toFixed(2) + " x " + window.screen.height * window.devicePixelRatio.toFixed(2) + " (width X height)");
        console.log("Device Pixel Ratio: " + window.devicePixelRatio.toFixed(2));
        console.log("Breakpoint Resolution: " + window.screen.width + " x " + window.screen.height + " (width x height)");
        console.log("Browser Viewport Size: " + $(window).width() + " x " + $(window).height() + " (width x height)");
        console.log("Size of HTML document: " + $(document).width() + " x " + $(document).height() + " (width x height)");
    }

    handleClearConsole = () => {
        this.clearLog = true;
        window.console.clear();
        let clearLog = [];
        this.setState({ log: clearLog });
    }

    handlePropertyValueChange = (event, className, propertyName, id) => {
        //reset the value in the text field
        let classPropertiesCopy = [...this.state.classProperties];
        classPropertiesCopy[id].propertyValue = event.target.value;
        this.setState({ classProperties: classPropertiesCopy });

        //change the actual css for that class
        $('.' + className).css(propertyName, event.target.value);
    }

    borderTimer;

    handleFindParent = () => {
        let parentClass = null;
        let parents = null;
        if (this.currentClass !== '') {
            parents = $('.' + this.currentClass).parents();
        }


        if (parents === null) {
            console.log("NO IMMEDIATE PARENT - NULL was found");
            return;
        }

        if (parents.get(0).className !== '') {
            parentClass = parents.get(0).className;
            this.currentClass = parentClass;
            this.makeBorder(parentClass);
            this.addPropertiesToList(parentClass);
        } else {
            console.log("NO IMMEDIATE PARENT WITH A CLASS FOUND");
        }
    }

    makeBorder = (classForBorder) => {
        //record the parent's original border
        let currentBorder = $('.' + classForBorder).css('border');

        //put a border around the parent
        $('.' + classForBorder).css('border', '5px solid #ff3fff');

        //put the border back to the original
        this.borderStopper = setTimeout(function () {
            $('.' + classForBorder).css('border', currentBorder);
            clearTimeout(this.borderStopper);
        }, 2000);
    }

    handlePropertyIncrement = (direction, className, propertyName, id, propertyValue) => {
        let classPropertiesCopy = [...this.state.classProperties];
        let currentvalue = '';
        let incrementSuffix = '';
        if (propertyValue.indexOf('px') > 0) {
            currentvalue = propertyValue.substr(0, propertyValue.indexOf('px'));
            incrementSuffix = 'px';
        } else if (propertyValue.indexOf('%') > 0) {
            currentvalue = propertyValue.substr(0, propertyValue.indexOf('%'));
            incrementSuffix = '%';
        } else {
            return;
        }
        let newValue = (direction === 'up') ? parseInt(currentvalue) + 1 : parseInt(currentvalue) - 1;
        newValue = newValue.toString() + incrementSuffix;
        classPropertiesCopy[id].propertyValue = newValue;
        this.setState({ classProperties: classPropertiesCopy });

        //change the actual css for that class
        $('.' + className).css(propertyName, newValue);
    }

    handleFindAllComponents = () => {
        let previousListenersAdded = this.listenersAdded;
        //remove any old click listeners before adding new ones
        this.removeOldListeners(this.childArray);
        this.childArray = [];
        this.listenersAdded = 0;
        let children = document.getElementById('root').children;
        let initialArray = Object.values(children);
        this.findChildren(initialArray);
        this.setListeners(this.childArray);
        console.log("Found " + this.listenersAdded + " components (previously " + previousListenersAdded + ").");
    }

    handleMakeClickable = () => {
        this.setState({ clickable: !this.state.clickable });
        this.setListeners(this.childArray);
    }

    handleMinimize = () => {
        this.setState({ minimized: !this.state.minimized });
        document.onmouseup = null;
        document.onmousemove = null;
        document.ontouchend = null;
        document.ontouchmove = null;
        this.dragElement("inspector");
    }

    handleConsoleSwitch = () => {
        this.setState({ console: !this.state.console });
    }

    render() {

        let logEntries = this.state.log.map((entry, index) => {
            return <li key={index}>{entry}</li>
        });

        let propEntries = this.state.classProperties.map((propEntry, index) => {
            // return <li key={index}>{propEntry}</li>
            return <PropertyInfo {...propEntry}
                propertyIncrement={this.handlePropertyIncrement}
                valueChange={this.handlePropertyValueChange}
                key={index} id={index} />
        });

        return (
            !this.state.minimized ?
                <div>
                    <div id="inspector" className="inspectorBody inspector">
                        <div className="inspectorInfoContainer inspector">
                            <div id="inspectorHeader" className="inspectorHeader inspector">
                                <button className="minimizeButton inspector"
                                    onClick={this.handleMinimize}>_</button>
                                <span className="inspectorTitle inspector">Inspectorator 3000</span>
                                <button className="propertiesOrConsole inspector" onClick={this.handleConsoleSwitch}>{!this.state.console ? 'console' : 'inspect'}</button>
                            </div>

                            {!this.state.console ?
                            <div className="inspectorProps inspector">
                                <ul>
                                    <p>{propEntries}</p>
                                </ul>
                            </div>
                            :   
                            <div className="inspectorConsole inspector">
                                <ul>
                                    {logEntries}
                                </ul>
                            </div>
                            }

                            <div className="inspectorButtonPanel inspector">
                                <button className="inspectorMakeClickableButton inspector" onClick={this.handleFindParent}>Find Parent</button>
                                <button className="inspectorFindComponentsButton inspector" onClick={this.handleFindAllComponents}>Re-find All Components</button>
                            </div>
                            <div className="inspectorButtonPanel inspector">
                                <button className="inspectorMakeClickableButton inspector" onClick={this.handleMakeClickable}>{this.state.clickable === false ? 'Make Buttons Clickable' : 'Make Buttons Not Clickable'}</button>
                                <button className="inspectorClearConsoleButton inspector" onClick={this.handleClearConsole}>Clear Console</button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div id="inspector" className="minimizedInspector inspector">
                        <div className="inspectorInfoContainer inspector">
                            <div id="inspectorHeader" className="inspectorHeader inspector">
                                <button className="maximizeButton inspector" onClick={this.handleMinimize}>+</button>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Inspector;
